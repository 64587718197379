<template>
  <div class="my-5" :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-9' : 'px-5'">

    <!-- ==== DATA SUMMARY PENJUALAN ==== -->
    <v-card
      outlined
      class="box-shadow border-radius pa-5 pb-1">
      <v-card-title class="body-1 px-5 text-second pt-0">
        DASHBOARD
      </v-card-title>
      <v-card-subtitle class="caption pl-5 text-third">
        <b>{{ filtered }}</b>
      </v-card-subtitle>
    </v-card>

  </div>
</template>

<script>
  import moment from 'moment';
  import Empty from "@/components/Empty";
  import { TokenService } from "@/service/Storage.Service";
  export default {
    data () {
      return {
        value2: [],
        process: {
          run: false,
        },
      }
    },
    computed: {
      user () {
        return JSON.parse(TokenService.getUser())
      },
      filtered(){
        return this.value2 ? moment(this.value2[0]).format('DD MMMM YYYY') : ''
      },
    },
    watch: {
    },
    components: {
      Empty
    },
    mounted () {
      var date = new Date();
      let firstDay = new Date(
        new Date().setDate(new Date().getDate())
      );
      let lastDay = new Date(
        new Date().setDate(new Date().getDate() - new Date().getDay() + 6)
      );
      const filter = [firstDay, lastDay];
      this.value2 = filter
    },
    methods: {
    }
  }
</script>

<style>
  .line {
    border-left: 6px solid #F05326 !important;
    transition: 0.3s;
  }

</style>